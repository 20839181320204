import "./SoftwarePage.css";

import { Link } from "react-router-dom";


export default function SoftwarePage() {

  document.title = "Software | Vocalyse"

  return (
    <main className="page-grid allsoftware-page">
      <section className="allsoftware_header">
        <div className="allsoftware_header-container">
          <h1 className="page-heading">Our Software.</h1>
          <p>We have a wide variety of text-handling software products to provide you with useful textual insights.</p>
        </div>
      </section>

      <section className="software_highlight">
        <div className="software_highlight-container">
          <div>
            <p className="highlight-tag">Available now!</p>
            <h2>Lexical Feature Marker</h2>
          </div>

          <p>Our main educational/research tool, capable of highlighting the lexical components of the English language within a given text.</p>
          <div className="software_highlight-buttons">
            <a href="https://app.vocalyse.co.uk/lfm" className="primary-button small-button">Try now!</a>
            <Link to="./lfm" className="secondary-button small-button">More info</Link>
          </div>
        </div>
      </section>

      <section className="software_software">
        <div className="software_software-container">
          <h2>All Software</h2>
          <ul className="software_software-list">
            <li className="software_entry">
              <div>
                <h3>Document Comparison</h3>
                <p>This document comparison software, our main area of development, is capable of highlighting similarities and differences between large quantities of textual data without the need for keywords or knowledge of the texts.</p>
              </div>
              <div className="software_entry-buttons">
                <Link to="./document-comparison#demo" className="primary-button small-button">Try demo</Link>
                <Link to="./document-comparison" className="secondary-button small-button">More info</Link>
              </div>
            </li>
            <li className="software_entry">
              <div>
                <h3>Jangle</h3>
                <p>One of our text analysis programs Jangle, the Jaccard N-Gram Lexical Evaluator, compares texts based on groups of words known as n-grams. It is capable of finding and comparing a selectable length of strings of words and calculating similarity scores based on this.</p>
              </div>
              <div className="software_entry-buttons">
                <Link to="./jangle#demo" className="primary-button small-button">Try demo</Link>
                <Link to="./jangle" className="secondary-button small-button">More info</Link>
              </div>
            </li>
            <li className="software_entry">
              <div>
                <h3>PubMed Search</h3>
                <p>A version of our document comparison software designed specifically to work quickly with the PubMed database of articles. Simply upload your own abstract or extract of text, and the software will show you the articles that are most similar in text content and writing style.</p>
              </div>
              <div className="software_entry-buttons">
                <Link to="./pubmed-search#demo" className="primary-button small-button">Try demo</Link>
                <Link to="./pubmed-search" className="secondary-button small-button">More info</Link>
              </div>
            </li>
            <li className="software_entry">
              <div>
                <h3>Lexical Feature Marker</h3>
                <p>Our main educational/research tool, capable of highlighting the lexical components of the English language within a given text.</p>
              </div>
              <div className="software_entry-buttons">
                <a href="https://app.vocalyse.co.uk/lfm" className="primary-button small-button">Try now!</a>
                <Link to="./lfm" className="secondary-button small-button">More info</Link>
              </div>
            </li>
          </ul>
        </div>
      </section>

      <section className="software_software">
        <div className="software_software-container">
          <h2>Custom software</h2>
          <p>Got a specific requirement you think we can fulfill? Get in touch, and we can discuss what is possible.</p>
          <Link to="/contact" className="primary-button small-button">Contact</Link>
        </div>
      </section>

    </main>
  )
}

import SoftwareTemplate from "./SoftwareTemplate";
import ComparisonCenter from "./doccomp/ComparisonCenter";


export default function DocCompPage() {

  return (
    <SoftwareTemplate
      name={"Document Comparison"}
      tag={"Finding without searching."}
      desc={"This document comparison software, our main area of development, is capable of highlighting similarities and differences between large quantities of textual data without the need for keywords or knowledge of the texts."}
      DemoDesc={DemoDesc}
      Demo={<ComparisonCenter />}
      features={["Upload unlimited length custom text files.", "View matching words list.", "Control over what a matching sentence means."]}
    />

    //   <section id="extra-section">
    //     <h2>Extra information:</h2>
    //     <p>This is our main area of development. We are able to read any collection of textual data without the need for indexing or a database, although if the texts are already in a database we can make use of those. This means that there is no special data preparation for the use of the program, which is also distinctive in that it uses entire texts as an input and rather than searching for the words, compares all the words in the text with all the words in each of the documents contained in the dataset, or a subset built by a user.</p>
    //     <p>The program returns a list of results with the fully transparent ranking by vocabulary in common and terms found in common between the starting document and any of the other documents. The central hypothesis is that an expert generated the initial text, experts will have generated each of the texts under review and users will be subject experts, so by showing the end user those documents which have the most vocabulary in common they will quickly and easily be able to judge the actual relevance of each article to their interest. The second hypothesis is that while there are millions or billions of documents, the areas of expertise are by their nature much more restricted in size and have special and distinctive vocabulary, so the actual number of relevant documents will be much lower and available by intelligent subset selection. Even the ubiquitous laboratory rat can only be found in 1.7 million PubMed abstracts, or less than 5% of the whole, for example. The program always reads all the texts in the dataset or subset and produces a second report showing the number of texts at each level of matching, assuring completeness of coverage and providing a rapid way of evaluating where to stop reading.</p>
    //     <p>The other aspect which assists this is that the full sentences, paragraphs or complete text are always shown fully marked with the matching vocabulary, removing the need for further clicking and reading. Because the output is a HTML file the user can use the search mechanism built into browsers and can also save the report for use in a browser or in a spreadsheet that can recognise HTML, where all the markup will be preserved.</p>
    //     <p>Our development work has been with the abstracts held by PubMed, but we are able to handle the full text articles of PubMed Central or any collection of full or partial texts held by institutions or commercial organisations. We offer consultancy in how best to handle the integration of the particular data into the Document Reader and programming to allow recognition of the elements inside a document. XML is our preferred format for this task but we can handle other formats as well.</p>
    //     <p>We have developed a stand-alone version which is capable of handling subsets of reasonable size on regular computers. The program was designed and runs quickly on a standard quad-core system.</p>
    //   </section>

  )
}


function DemoDesc() {
  return (
    <p>
      The two texts below have been compared using the Document Comparison software.
      If a sentence in one text has a match with one in the other, the matching words are highlighed green with partial matches in blue.
      Hovering or clicking a sentence that has a match reveals its matching sentence.
    </p>
  )
}

import "./Footer.css";

import { Link } from "react-router-dom";


export default function Footer() {
  return (
    <footer id="footer">
      <div className="footer_container">
        {/* <h4 className="footer_logo">Vocalyse</h4> */}
        {/* <div className="footer_content">
          <ul className="footer_section">
            <li>Products</li>
            <li><Link to="/software/lfm">Lexical Feature Marker</Link></li>
            <li><Link to="/software/document-comparison">Document Comparison</Link></li>
            <li><Link to="/software/jangle">Jangle</Link></li>
            <li><Link to="/software/pubmed-search">PubMed Search</Link></li>
          </ul>

          <ul className="footer_section">
            <li>Pages</li>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/software">Software</Link></li>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/contact">Contact</Link></li>
          </ul>
        </div> */}

        <div className="footer_legal">
          <span>&#169; 2024, Vocalyse Limited</span>

          <div>
            <Link to="/">Home</Link>
            <Link to="/software">Software</Link>
            <Link to="/about">About</Link>
            <Link to="/contact">Contact</Link>
            {/* Email: <a href="mailto:emailus@vocalyse.co.uk">contact@vocalyse.co.uk</a> */}
          </div>
        </div>
      </div>
    </footer>
  )
}
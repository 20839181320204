import "./ContactPage.css";

import { useEffect } from "react";
import { useLocation } from "react-router-dom";


export default function ContactPage() {

  document.title = "Contact us | Vocalyse";

  const { hash } = useLocation();
  useEffect(() => {
    if (hash === "#missing") {
      alert("Failed to submit form: missing required values.")
    }
  }, [hash]);


  function validateForm(e) {

    document.getElementById("contact_form-name-error").innerText = "";
    document.getElementById("contact_form-email-error").innerText = "";
    document.getElementById("contact_form-subject-error").innerText = "";
    document.getElementById("contact_form-message-error").innerText = "";

    var foundError = false;

    if (document.getElementById("contact_form-name").value === "") {
      foundError = true;
      const errorSpan = document.getElementById("contact_form-name-error")
      errorSpan.innerText = "Name is required";
      document.getElementById("contact_form-name").addEventListener("keyup", function changeHandler() {
        errorSpan.innerText = "";
        this.removeEventListener("keyup", changeHandler);
      })
    }

    if (document.getElementById("contact_form-email").value === "") {
      foundError = true;
      const errorSpan = document.getElementById("contact_form-email-error");
      errorSpan.innerText = "Email is required";
      document.getElementById("contact_form-email").addEventListener("keyup", function changeHandler() {
        errorSpan.innerText = "";
        this.removeEventListener("keyup", changeHandler);
      })
    }

    if (document.getElementById("contact_form-subject").value === "") {
      foundError = true;
      const errorSpan = document.getElementById("contact_form-subject-error");
      errorSpan.innerText = "Subject is required";
      document.getElementById("contact_form-subject").addEventListener("keyup", function changeHandler() {
        errorSpan.innerText = "";
        this.removeEventListener("keyup", changeHandler);
      })
    }

    if (document.getElementById("contact_form-message").value === "") {
      foundError = true;
      const errorSpan = document.getElementById("contact_form-message-error")
      errorSpan.innerText = "Message is required";
      document.getElementById("contact_form-message").addEventListener("keyup", function changeHandler() {
        errorSpan.innerText = "";
        this.removeEventListener("keyup", changeHandler);
      })
    }


    if (foundError) {
      e.preventDefault();
    }
  }


  return (
    <main className="page-grid contact-page">
      <section className="contact_header">
        <h1 className="page-heading">Contact us.</h1>
      </section>

      <section className="contact_main">
        <div className="contact_main-container">
          <div className="contact_main-left">
            <h2>We're happy to talk</h2>

            <div className="contact_main-left-inner">
              <h3>Find what we do interesting?</h3>
              <p>Talk to us if you're interested in our products.</p>
            </div>

            <div className="contact_main-left-inner">
              <h3>Got specific requirements?</h3>
              <p>Let us know, and we'll work through it with you.</p>
            </div>

            <div className="contact_main-left-inner">
              <h3>Simply want to know more?</h3>
              <p>You can get in touch for a chat about how our software works.</p>
            </div>

          </div>

          <div className="contact_main-right">
            <div className="contact_main-form-title">
              <h2>Get in touch</h2>
              <p className="contact_main-subtext">* all fields are required</p>              
            </div>

            <form className="contact_main-form" onSubmit={validateForm} method="post" action="/cgi-bin/FormMail.pl">

              <input type="hidden" name="required" value="name,email,subject,message" />

              <div className="contact_form-group">
                <label>Name</label>
                <span className="contact_form-error" id="contact_form-name-error"></span>
                <input type="text" name="name" placeholder="Name" id="contact_form-name" />
              </div>

              <div className="contact_form-group">
                <label>Email</label>
                <span className="contact_form-error" id="contact_form-email-error"></span>
                <input type="email" name="email" placeholder="Email" id="contact_form-email" />
              </div>

              <div className="contact_form-group">
                <label>Subject</label>
                <span className="contact_form-error" id="contact_form-subject-error"></span>
                <input type="text" name="subject" placeholder="Subject" id="contact_form-subject" />
              </div>

              <div className="contact_form-group">
                <label>Message</label>
                <span className="contact_form-error" id="contact_form-message-error"></span>
                <textarea name="message" placeholder="Message" id="contact_form-message" />
              </div>

              <input type="hidden" name="sort" value="order:name,email,subject,message" />
              <input type="hidden" name="missing_fields_redirect" value="/contact#missing" />
              <input type="hidden" name="redirect" value="/thankyou" />

              <button type="submit" className="primary-button small-button">Submit</button>
            </form>
          </div>
        </div>
      </section>

    </main>
  )
}
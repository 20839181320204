import { useState } from "react";
import "./NavBar.css";

import { Link, useLocation } from 'react-router-dom';


export default function NavBar() {

  const location = useLocation();
  const darkNavbar = location.pathname === "/" || location.pathname.startsWith("/software/") || location.pathname.startsWith("/about");

  function clickListener(e) {
    const mobileTarget = document.getElementById("navbar-mobile");
    if (!e.composedPath().includes(mobileTarget)) {
      openCloseMobile(false);
    }
  }

  const [showMobile, setShowMobile] = useState(false);
  function openCloseMobile(open) {
    setShowMobile(open);

    if (open) {
      document.addEventListener("mouseup", clickListener);
    }
    else {
      document.removeEventListener("mouseup", clickListener);
    }
  }


  return (
    <header id="navbar" className={darkNavbar ? "navbar-dark" : "navbar-light"}>
      <div className="navbar">
        <div className="navbar-title">
          <button className="navbar-hamburger" onClick={() => openCloseMobile(true)}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5" />
            </svg>
          </button>
          <Link to="/" className="navbar-logo">
            {/* <img src="/favicon.png" alt="Vocalyse Logo" /> */}
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="-9 0 18 18">
              <path d="M0 18A1 1 0 000 0L0 3.5 4.33 3.5Q5.345 4.286 6.063 5.5L0 5.5 0 3.5-5.809 3.5Q-6.693 4.442-7.193 5.5L0 5.5 0 8 6.928 8Q7.077 9 6.928 10L0 10 0 8-7.937 8Q-8.063 9-7.937 10L0 10 0 12.5 6.063 12.5Q5.334 13.725 4.331 14.5L0 14.5 0 12.5-7.193 12.5Q-6.69 13.554-5.809 14.5L0 14.5Z"/>
            </svg>
            Vocalyse
          </Link>
        </div>

        <nav>
          <ul className="navlist">
            <li><Link to="/">home</Link></li>
            <li><Link to="/about">about</Link></li>
            <li><Link to="/software">software</Link></li>
            <li><Link to="/contact" className="primary-button round-button">Get in Touch</Link></li>
          </ul>
        </nav>
      </div>

      <div id="navbar-mobile" className={"navbar-mobile" + (showMobile ? " active" : "")}>
        <button className="navbar-close" onClick={() => openCloseMobile(false)}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="5 5 18 18" strokeWidth="2" stroke="currentColor">
            <path d="M6 18 18 6M6 6l12 12" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </button>

        <nav>
          <ul className="navlist-mobile">
            <li onClick={() => openCloseMobile(false)}><Link to="/">home</Link></li>
            <li onClick={() => openCloseMobile(false)}><Link to="/about">about</Link></li>
            <li onClick={() => openCloseMobile(false)}><Link to="/software">software</Link></li>
            <li onClick={() => openCloseMobile(false)} className="navbar-mobile-button">
              <Link to="/contact" className="primary-button round-button">Get in Touch</Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  )
}
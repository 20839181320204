import { Link } from "react-router-dom";


export default function ContactSuccessPage() {

  return (
    <main className="page-grid contact-page">
      <section className="contact-success_header">
        <h1 className="page-heading">Thanks for getting in touch!</h1>
        <p>Your message has been received and we will get back to you soon!</p>
        <Link to="/">back home</Link>
      </section>
    </main>
  )
}
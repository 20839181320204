import './App.css';

import { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

import NavBar from './components/NavBar';
import Footer from './components/Footer';

import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import PubMedPage from './pages/software/PubMedPage';
import JanglePage from './pages/software/JanglePage';
import LFMPage from './pages/software/LFMPage';
import DocCompPage from './pages/software/DocCompPage';
import NotFound from './pages/error/NotFound';
import SoftwarePage from './pages/SoftwarePage';
import ContactSuccessPage from './pages/ContactSuccessPage';


export default function App() {

  const { pathname, hash } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    if (hash) document.querySelectorAll(hash)?.[0]?.scrollIntoView({ behavior: "smooth" })
  }, [pathname, hash]);

  return (
    <>
      <NavBar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/thankyou" element={<ContactSuccessPage />} />

          <Route path="/software" element={<SoftwarePage />} />
          <Route path="/software/lfm" element={<LFMPage />} />
          <Route path="/software/document-comparison" element={<DocCompPage />} />
          <Route path="/software/pubmed-search" element={<PubMedPage />} />
          <Route path="/software/jangle" element={<JanglePage />} />

          <Route path="*" element={<NotFound />} />          
        </Routes>
      <Footer />
    </>
  );
}
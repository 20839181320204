import "./AboutPage.css";

import { Link } from "react-router-dom";


export default function AboutPage() {

  document.title = "About | Vocalyse";

  return (
    <main className="page-grid about-page">
      <section className="about_header">
        <div className="about_header-container">
          <div>
            <h1 className="about_small-heading">About us</h1>
            <h2 className="page-heading">At Vocalyse</h2>
          </div>

          <p>At Vocalyse we offer consultancy services on handling any type of textual data. We have a number of Java and Python programs that we have developed over the years and can experiment with examples of different types of documents. We have advised on the handling of plagiarism detection, accessing medical databases, monitoring music reviews, identifying lapsed patents and trademarks, and more. We have also designed educational software for the exploration of language use, including the development of Tetrapla, a multilingual parallel concordancer, and the Lexical Feature Marker, which has been used at the University of Leeds for many years both as a teaching and research tool.</p>          
        </div>

      </section>

      {/* <section className="about_us">
        <h2 className="about_heading">Who are we</h2>
        
        <div className="about_us-people">
          <div>
            <div className="about_us-person">
              <div className="about_us-person-title">
                <h3 className="about_us-name">David Woolls</h3>
                <p className="about_us-caption">Co-Founder</p>            
              </div>

              <p>
                Our co-founder and resident linguistics expert, David Woolls, is experienced in this space.
                Prior to discovering programming and linguistics in his late thirties, he was a qualified and practising Chartered Accountant and then went on to gain a BA (Hons) in Theology from Oxford University, where he studied as a mature student.
              </p>
              <p>
                David is well-published in the realm of linguistic and plagiarism studies.
                He has chapters in three international handbooks:
                <ul>
                  <ArticleEntry
                    title={"Computational Forensic Linguistics: Searching for similarity in large specialised corpora"}
                    book={"The Routledge Handbook of Forensic Linguistics"}
                    year={"2010"}
                  />
                  <ArticleEntry
                    title={"Detecting Plagiarism"}
                    book={"The Oxford Handbook of Language and Law"}
                    year={"2012"}
                  />
                  <ArticleEntry
                    title={"Computational forensic linguistics: Computer-assisted document comparison"}
                    book={"The Routledge Handbook of Forensic Linguistics, Second Edition"}
                    year={"2020"}
                  />
                </ul>
              </p>
              
              <p>
                As well as chapters and entries in the following:
                <ul>
                  <ArticleEntry
                    title={"An entry on Plagiarism"}
                    book={"The Elsevier Encyclopedia of Language and Linguistics, 2nd Edition"}
                    year={"2006"}
                  />
                  <ArticleEntry
                    title={"A chapter on creating a Multilingual Parallel Concordancer"}
                    book={"Multilingual Corpora in Teaching and Research"}
                    year={"2000"}
                  />
                  <ArticleEntry
                    title={"A chapter (with Dr Alison Johnson of The University of Leeds)"}
                    book={"Introducing Applied Linguistics: Concepts and Skills"}
                    year={"2009"}
                  />
                </ul>
              </p>
              
              <p>
                In addition he has published papers in:
                <ul>
                  <li>
                    Forensic Linguistics: The International Journal of Speech, Language and the Law (1998 and 2003)
                  </li>
                  <li>
                    in Studies in Bibliography (2001), which was on computer-assisted authorship identification of 18th century pamphlets, (with Professor Pamela Clemit of Durham University).
                  </li>
                </ul>
              </p>    
            </div>
          </div>

          <div>
            <div className="about_us-person">
              <div className="about_us-person-title">
                <h3 className="about_us-name">Stuart Eaton</h3>
                <p className="about_us-caption">Co-Founder</p>
              </div>

              <p>
                Stuart Eaton spent 25 years leading teams managing high-profile pension funds such as Newton and Insight, where in both cases he was Head of UK equities. More recently, he has been an active private investor as well as a director of Elute Intelligence Limited and co-founder of Vocalyse Limited. He has also been a non-executive director on a number of quoted and unquoted companies.
              </p>
            </div>

          </div>
        </div>

      </section> */}

      {/* <section className="about_where">
        <h2>Where we've been</h2>
        <p>We have attended a number of conferences on patent searching, given a presentation to the Patent Users Information Group in Atlanta, Georgia, USA, and most recently a video presentation on medical data curation and annotation.</p>
      </section> */}

      <section className="about_more">
        <h2>More coming soon!</h2>
        <p>In the meantime, we're happy to talk- get in touch <Link to="/contact">here</Link>.</p>
      </section>
    </main>
  )
}


function ArticleEntry({ title, book, year }) {
  return (
    <li className="about_article-entry">
      <span className="about_article-title">{title}</span>
      <span className="about_article-book">{book} <span className="about_article-year">({year})</span></span> 
    </li>
  )
}

import "./LFMDemo.css";

import SoftwareTemplate from "./SoftwareTemplate";
import { useEffect, useState } from "react";

import functionwords from "../../assets/lfm/features.json";
import { Link } from "react-router-dom";
const pronouns = functionwords.pronouns;
const modals = functionwords.modals;
const prepositions = functionwords.prepositions;
const tobe = functionwords.tobe;
const stopwords = functionwords.function;


export default function LFMPage() {

  return (
    <SoftwareTemplate
      name={"Lexical Feature Marker"}
      tag={"Reveal the ways the English language is used."}
      desc={"Our main educational/research tool, capable of highlighting the lexical components of the English language within a given text."}
      DifferentButton={<Link to="https://app.vocalyse.co.uk/lfm" className="primary-button small-button">Try now!</Link>}
      Details={LFMFeatures}
      Demo={<LFMDemo />}
      features={["Comprehensive breakdown of words, classifications, and occurrences.", "Word sentence distribution within the text.", "Unlimited characters in input text.", "Custom word highlighting."]}
    />

    //   <section id="extra-section">
    //     <h2>Extra information:</h2>
    //     <p>The Lexical Feature Marker is our main education/research tool. Simply choose a docx or plain .txt file from your computer in English and then click on one or more of the buttons for the program to show you how the English language is used.</p>
    //     <p>
    //       For example, if you are unsure what a pronoun or preposition is, you can just click on one of those buttons and the program will show you what they are and where they appear.

    //       If you DO know what these words are the same button clicks will show you HOW the words are being used.

    //       This is true for any of the word classes stored in the computer. But you can also add your own words in the Your Word List box. This lets you discover how often and where they are used, or if you know some words have been spelt differently (e.g. thier for their) you can enter those variants and either look at them by themselves or in addition to the correctly spelt words.
    //     </p>
    //     <p>
    //       If you have the transcript of a conversation, you can see how the different people use the same words. Simply enter the list of names, in the style which is used in the transcript. e.g.

    //       Judge:

    //       Prosecution:

    //       Defence:

    //       all on separate lines in the box.

    //       If you want to know how the words you have selected are spread through a text, click the Distribution box and the program will show you which sentences they occur in. A dot means there are no instances, 1, 2, 3 etc, will show you that the particular sentence has 1, 2 or 3 occurrences of that word.
    //     </p>
    //     <p>
    //       Researchers can use the LFM to analyse authorial style, sentence building or many other points of interest in describing particular genres or registers used when writing or speaking.
    //     </p>
    //   </section>

  )
}


function LFMFeatures() {
  return (
    <div className="lfm-features">
      <p className="lfm-features_overview">Our main educational/research tool capable of highlighting the lexical components of the English language within a given text.</p>

      <div className="lfm-features_box-container">
        <div className="lfm-features_box">
          <h2>Split.</h2>
          <p>Split and tokenise text into its sentences and words</p>
        </div>
        <div className="lfm-features_box">
          <h2>Mark.</h2>
          <p>Find and highlight where English linguistic techniques are used</p>
        </div>
        <div className="lfm-features_box">
          <h2>Analyse.</h2>
          <p>Calculate the use and distribution of different lexical features</p>
        </div>
      </div>

      <div className="lfm-features_lexical-container">
        <div className="lfm-features_lexical-left">
          <div>
            <h3>Function words</h3>
            <p>These are the most common words that appear in the English language and are used 'functionally' within a sentence without adding any substance or content. The most commonly found function words are the, of, and, etc.</p>
          </div>
          
          <div>
            <h3>Content words</h3>
            <p>These are the substance of sentences that provide information and context to provide meaning to what is written.</p>
          </div>
          
        </div>

        <div className="lfm-features_lexical-right">
          <div>
            <h3>Pronouns</h3>
            <p>Pronouns replace nouns in sentences to refer to people, places, things, and concepts.</p>
          </div>
          
          <div>
            <h3>Modals</h3>
            <p>A modal verb indicates a likelihood, possibility, permission, or necessity.</p>
          </div>
          
          <div>
            <h3>Prepositions</h3>
            <p>Prepositions show relationships between nouns and another word in the sentence.</p>
          </div>
          
          <div>
            <h3>'To-be' verbs</h3>
            <p>To-be verbs represent a state of being or existence of actions, nouns, and characteristics.</p>
          </div>
        </div>
      </div>
    </div>
  )
}


function LFMDemo() {

  const maxChars = 250;

  const [demoValue, setDemoValue] = useState("If you enter text into this textbox, you will find that the lexical features are revealed!");

  useEffect(() => {
    let text = demoValue;
    if (text.length > maxChars) {
      text = text.substring(0, maxChars);
      setDemoValue(text);
    }

    let html = "";
    for (let word of text.split(/(?=[\W+])|(?<=[\W+])/g)) {
      if (pronouns.includes(word.toLowerCase())) html += `<span data-wordtype="pronoun">${word}</span>`;
      else if (modals.includes(word.toLowerCase())) html += `<span data-wordtype="modal">${word}</span>`;
      else if (prepositions.includes(word.toLowerCase())) html += `<span data-wordtype="preposition">${word}</span>`;
      else if (tobe.includes(word.toLowerCase())) html += `<span data-wordtype="tobeverb">${word}</span>`;
      else if (stopwords.includes(word.toLowerCase())) html += `<span data-wordtype="function">${word}</span>`;
      else html += word;
    }
    document.getElementById("lfmdemo_text").innerHTML = html;
  }, [demoValue]);

  function scrollDiv(e) {
    document.getElementById("lfmdemo_text").scrollTop = e.target.scrollTop;
  }

  return (
    <div className="lfmdemo">
      <div className="lfmdemo_container">
        <div className="lfmdemo_topbar"></div>
        <div className="lfmdemo_textbox">
          <span className="lfmdemo_characters">{demoValue.length}/{maxChars}</span>
          <div id="lfmdemo_text" className="lfmdemo_text-display" />
          <textarea className="lfmdemo_text-input" onScroll={scrollDiv}
                    value={demoValue} onChange={e => setDemoValue(e.target.value)} />
        </div>
      </div>
      <div className="lfmdemo_legend-container">
        <h3>Lexical key:</h3>
        <ul className="lfmdemo_legend">
          <li className="function-key">Function words</li>
          <li className="pronoun-key">Pronouns</li>
          <li className="modal-key">Modals</li>
          <li className="preposition-key">Prepositions</li>
          <li className="tobe-key">'To be' verbs</li>
        </ul>
      </div>      
    </div>
  )
}

import "./HomePage.css";

import { Link } from "react-router-dom";
import { ReactComponent as HomepageImage} from "../assets/images/homeimage.svg";
import { useEffect, useState } from "react";


export default function HomePage() {

  document.title = "Vocalyse"

  const homepageText = "Building Your Business Bespoke Textual Insights";
  const [titleText, setTitleText] = useState("");
  const [hiddenText, setHiddenText] = useState(homepageText);
  useEffect(() => {
    async function typeTitle() {
      const titleLen = homepageText.length;
      for (let i=0; i<titleLen; i++) {
        let delay = 90 - Math.random() * 50;
        await new Promise(r => setTimeout(r, delay));

        setTitleText(homepageText.slice(0, i+1));
        setHiddenText(homepageText.slice(i+1, titleLen));
      }
      setTitleText(homepageText);
      setHiddenText("");
    }
    typeTitle();
  }, []);

  const softwareLookup = {
    "doccomp": "Document Comparison",
    "lfm": "Lexical Feature Marker",
    "jangle": "Jangle",
    "pubmed": "PubMed Search",
  }
  const [selectedSoftware, setSelectedSoftware] = useState("doccomp");
  function changeSoftware(newvalue) {
    setSelectedSoftware(newvalue);
  }


  return (
    <main className="page-grid homepage">
      <section className="homepage_header">
        <div className="homepage_header-container">
          <div className="homepage_header-left">
            <h1 className="page-heading">
              {titleText}
              {hiddenText.length > 0 &&
                <span className="homepage_header-hidden">
                  {hiddenText}<span className="homepage_header-cursor">|</span>
                </span>
              }
            </h1>
            <p>Your go-to for custom language-based software that just works.</p>
            <Link to="/software" className="primary-button homepage-main-button">Explore Software</Link>
          </div>
          <div className="homepage_header-right">
            <div className="homepage_header-image">
              <HomepageImage />
            </div>
          </div>
        </div>

      </section>

      <section className="homepage_about">
        <div className="homepage_about-container">
          <h2 className="homepage_about-heading">Built to work.</h2>
          <p>At Vocalyse we pride ourselves on reliability. Our experience and expertise mean our software is capable of comparing, analysing, and searching large quantities of text with guaranteed results.</p>
          <Link to="/about" className="primary-button">Find out more</Link>
        </div>
      </section>

      {/* <section className="homepage_soft">
        <div className="homepage_soft-container">
          <h2 className="homepage_soft-heading">.</h2>
          <p>With our software you don't need to know what you're searching for, just provide the text and the program does the rest.</p>
          <Link to="/software" className="primary-button">Have a look</Link>
        </div>
      </section> */}

      <section className="homepage_software">
        <h2>Our software.</h2>
        <div className="homepage_software-container">
          <ul className="homepage_software-list">
            {Object.keys(softwareLookup).map((val, index) => {
              return (
                <SoftwareListEntry key={index} value={val} selected={selectedSoftware} onChange={changeSoftware}>
                  {softwareLookup[val]}
                </SoftwareListEntry>
              )
            })}
          </ul>
          <div className="homepage_software-display">
            <SoftwareDisplay
              hidden={selectedSoftware !== "doccomp"} link="document-comparison"
              name="Document Comparison" subtitle="Finding without searching."
              description="Our quick and effective document comparison software compares texts of any length entirely and analyses similarity."
            />
            <SoftwareDisplay
              hidden={selectedSoftware !== "lfm"} link="lfm"
              name="Lexical Feature Marker" subtitle="Revealing the complexities of the English language."
              description="Our main educational/research tool is capable of highlighting the lexical components of the English language within a given text."
            />
            <SoftwareDisplay
              hidden={selectedSoftware !== "jangle"} link="jangle"
              name="Jangle" subtitle="Jaccard n-Gram Lexical Evaluator."
              description="A simple yet powerful piece of software that makes use of consecutive groups of words to calculate similarities between texts."
            />
            <SoftwareDisplay
              hidden={selectedSoftware !== "pubmed"} link="pubmed-search"
              name="PubMed Search" subtitle="Searching a database of medical articles with completeness."
              description="A version of our document comparison software, designed specifically to search the PubMed database of articles quickly and effectively."
            />
          </div>
        </div>
      </section>

      <section className="homepage_enquire">
        <div className="homepage_enquire-container">
          <h2>Got a specific need?</h2>
          <p>Get in touch and we can work with you to build you the software you require.</p>
          <Link to="/contact" className="primary-button round-button enquire-button">Contact us</Link>
        </div>
      </section>

      {/* <section className="homepage_faq">
        <div className="homepage_faq-container">
          <h2>FAQ</h2>
        </div>
      </section> */}
    </main>
  )
}


function SoftwareListEntry({ value, selected, onChange, children, ...props }) {

  const active = selected === value;

  return (
    <li {...props}>
      <label className={active ? "active" : ""}>
        <input type="radio" name="homepage-software" value={value}
               onChange={() => onChange(value)}
               checked={active} />
        {children}
      </label>
    </li>
  )
}


function SoftwareDisplay({ hidden, link, name, subtitle, description }) {
  return (
    <div hidden={hidden}>
      <div className="homepage_software-block">
        <div>
          <h3 className="homepage_software-name">{name}</h3>
          <p className="homepage_software-subtitle">{subtitle}</p>          
        </div>

        <p className="homepage_software-description">{description}</p>

        {/* <h4>What it does</h4>
        <p>Lorem ipsum odor amet, consectetuer adipiscing elit. Curae nisi turpis vulputate lectus cras varius egestas hac. Pretium dapibus at himenaeos auctor. Lorem ipsum odor amet, consectetuer adipiscing elit. Curae nisi turpis vulputate lectus cras varius egestas hac. Pretium dapibus at himenaeos auctor.</p> */}

        <div className="homepage_software-buttons">
          {/* <Link to={"/software/"+link+"#demo"} className="secondary-button small-button">View demo</Link> */}
          <Link to={"/software/"+link} className="primary-button small-button">Find out more</Link>
        </div>
      </div>
    </div>
  )
}

import "./PubMedDemo.css";

import SoftwareTemplate from "./SoftwareTemplate";
import { Fragment } from "react";

import Data from "../../assets/pubmed/data.json";
import SearchText from "../../assets/pubmed/abstract.json";


export default function PubMedPage() {

  return (
    <SoftwareTemplate
      name={"PubMed Article Search"}
      tag={"Search millions of articles with completeness."}
      desc={"A version of our document comparison software designed specifically to work quickly with the PubMed database of articles. Simply upload your own abstract or extract of text and the software will show you the articles that are most similar in text content and writing style."}
      DemoDesc={DemoDesc}
      Demo={<PubMedDemo />}
      features={["Upload any length search text.", "Search the entirety of the PubMed database.", "Save subsets of PubMed articles for quick, specific searching in the future."]}
    />

    //   <section id="extra-section">
    //     <h2>Extra information:</h2>
    //     <p>It makes use of both single words and bigrams to make smart decisions on which articles are most similar to your entered text.</p>
    //   </section>

  )
}


function DemoDesc() {
  return (
    <p>
      In the demo below a search to the PubMed database has been made, with the top five matching results displayed.
      It is interactive, but the inputs cannot be edited and no new searches can be made here (full version below).
    </p>
  )
}


function PubMedDemo() {

  const todaysDate = new Date().toJSON().slice(0, 10);

  // search states
  const searchText = SearchText;

  // filter states
  const minDate = "";
  const maxDate = "";
  const authors = [];
  const journals = [];
  const mustFilters = ["cystic fibrosis"];
  const notFilters = [];
  const orFilters = [];

  // build section

  const searchResult = Data;


  function openTab(e) {
    for (let tab of document.getElementsByClassName("filter-bar-option-tab")) {
      tab.classList.remove("open");
    }

    e.target.nextSibling.classList.add("open");
  }

  function closeTab(e) {
    if ((!e.target.classList.contains("click-ignore") && !e.target.classList.contains("filter-bar-option")) || e.target.classList.contains("date")) {
      for (let tab of document.getElementsByClassName("open")) {
        tab.classList.remove("open");
      }
    }
  }

  document.addEventListener("mouseup", closeTab);

  return (
    <div className="pubmed-demo">
      <section className="section search-section">
        <div className="search-file-banner">
          <span className="section-heading"><h3>Search Text</h3> • compare any amount of text with PubMed articles</span>

          <span className="search-file-banner--buttons">
            <button className="pubmed-demo_button secondary-button">
              Clear
            </button>
            <button className="pubmed-demo_button upload-file-button">
              Upload a file
              <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                <path fillRule="evenodd" d="M12 3a1 1 0 0 1 .78.375l4 5a1 1 0 1 1-1.56 1.25L13 6.85V14a1 1 0 1 1-2 0V6.85L8.78 9.626a1 1 0 1 1-1.56-1.25l4-5A1 1 0 0 1 12 3ZM9 14v-1H5a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2h-4v1a3 3 0 1 1-6 0Zm8 2a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H17Z" clipRule="evenodd"/>
              </svg>
            </button>              
          </span>
        </div>

        <textarea className="search-file-text" value={searchText} readOnly={true}
          placeholder="Drag and drop a file, select one to upload, or type your search text manually..."
        />
      </section>


      <section className="section filter-bar">
        <span className="section-heading"><h3>Filters</h3> • narrow down searched articles to speed up comparison</span>
        <span className="filter-bar-filters">
          <div className="filter-bar-sect">
            Filter words:
            <div className="filter-bar-option-div">
              <span>
                <button className="filter-bar-option words" onClick={openTab}>
                  <span className="filter-bar-option-small">Must contain:</span>
                  {mustFilters.length === 0 ? "None" : mustFilters.length+" word"+(mustFilters.length>1 ? "s" : "")}
                </button>
                <div className="filter-bar-option-tab click-ignore">
                  limit articles to only those that include:
                  <div className="filter-box click-ignore">
                    {mustFilters?.map((filter, index) => {
                      return <span className="filter click-ignore" key={index}>{filter}</span>
                    })}
                    <AddFilter n={0} placeholder="add 'must' filter"/>
                  </div>
                </div>
              </span>

              <span>
                <button className="filter-bar-option words" onClick={openTab}>
                  <span className="filter-bar-option-small">Must <b>not</b> contain:</span>
                  {notFilters.length === 0 ? "None" : notFilters.length+" word"+(notFilters.length>1 ? "s" : "")}
                </button>
                <div className="filter-bar-option-tab click-ignore">
                  limit articles to only those that DON'T include:
                  <div className="filter-box click-ignore">
                    {notFilters?.map((filter, index) => {
                      return <span className="filter click-ignore" key={index}>{filter}</span>
                    })}
                    <AddFilter n={1} placeholder="add 'not' filter"/>
                  </div>
                </div>
              </span>

              <span>
                <button className="filter-bar-option words" onClick={openTab}>
                  <span className="filter-bar-option-small">Must contain one:</span>
                  {orFilters.length === 0 ? "None" : orFilters.length+" word"+(orFilters.length>1 ? "s" : "")}
                </button>
                <div className="filter-bar-option-tab click-ignore">
                  limit articles to only those that include one of:
                  <div className="filter-box click-ignore">
                    {orFilters?.map((filter, index) => {
                      return <span className="filter click-ignore" key={index}>{filter}</span>
                    })}
                    <AddFilter n={2} placeholder="add 'or' filter"/>
                  </div>
                </div>
              </span>
            </div>
          </div>

          <div className="filter-bar-sect">
            Filter dates:
            <div className="filter-bar-option-div">
              <span>
                <button className="filter-bar-option date"> 
                  <span className="filter-bar-option-small">Min date</span>
                  {minDate === "" ? "Any" : minDate}
                  <input id="mindate-input" type="date" placeholder="Any" max={todaysDate} value={minDate} onChange={() => {}} />
                </button>
              </span>

              <span>
                <button className="filter-bar-option date">
                  <span className="filter-bar-option-small">Max date</span>
                  {maxDate === "" ? "Any" : maxDate}
                  <input id="maxdate-input" type="date" placeholder="Any" max={todaysDate} value={maxDate} onChange={() => {}} />
                </button>
              </span>
            </div>
          </div>

          <div className="filter-bar-sect">
            Other filters:
            <div className="filter-bar-option-div">
              <span>
                <button className="filter-bar-option names" onClick={openTab}>
                  <span className="filter-bar-option-small">Authors</span>
                  {authors.length === 0 ? "All" : authors.length+" selected"}
                </button>
                <div className="filter-bar-option-tab click-ignore" tmp={0}>
                  Show only articles by these authors:
                  <div className="filter-box click-ignore">
                    {authors?.map((filter, index) => {
                      return <span className="filter click-ignore" key={index}>{filter}</span>
                    })}
                    <AddFilter n={3} placeholder="add an author"/>
                  </div>
                </div>                
              </span>

              <span>
                <button className="filter-bar-option names" onClick={openTab}>
                  <span className="filter-bar-option-small">Journals</span>
                  {journals.length === 0 ? "All" : journals.length+" selected"}
                </button>
                <div className="filter-bar-option-tab click-ignore" tmp={0}>
                  Show only articles in these journals:
                  <div className="filter-box click-ignore">
                    {journals?.map((filter, index) => {
                      return <span className="filter click-ignore" key={index}>{filter}</span>
                    })}
                    <AddFilter n={4} placeholder="add a journal"/>
                  </div>
                </div>                
              </span>

            </div>
          </div>

          <div className="start-search">
            <button className="pubmed-demo_button start-search-button">
              Search PubMed
              <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                <path fillRule="evenodd" d="M9 7V2.221a2 2 0 0 0-.5.365L4.586 6.5a2 2 0 0 0-.365.5H9Zm2 0V2h7a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V9h5a2 2 0 0 0 2-2Zm.5 5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm0 5c.47 0 .917-.092 1.326-.26l1.967 1.967a1 1 0 0 0 1.414-1.414l-1.817-1.818A3.5 3.5 0 1 0 11.5 17Z" clipRule="evenodd"/>
              </svg>
            </button>
          </div>
        </span>
      </section>

      <section className="section build-section">
        <span className="section-heading"><h3>Results</h3> • showing the top 5 matching articles in the PubMed database</span>
        <div className='search-section--output-div' style={{overflowX: "auto", maxHeight: "400px"}}>
          <table className="search-section--output-table">
            <tbody>
              {searchResult
              ? searchResult.map((res, index) => {
                  return <SearchResult key={index} article={res} />
                })
              : <></>
              }
            </tbody>
          </table>          
        </div>
      </section>
    </div>
  )
}


function AddFilter({ n, placeholder, setFunc }) {
  return (
    <form className="click-ignore" onSubmit={async (e) => {
        e.preventDefault();
        let input = document.getElementById("add-filter-"+n);
        if (input.value === "") return;
        await setFunc(prev => {
          if (prev.includes(input.value)) return prev;
          else return [...prev, input.value]
        });
        input.value = "";
      }}>
      <input id={"add-filter-"+n} className="add-filter-input click-ignore" placeholder={placeholder} />
      <button className="add-filter-button click-ignore">+</button>
    </form>
  )
}


function SearchResult({ article }) {
  return (
    <tr>
      <td>
        <div>
          {article.exact
          ? <p className='pm-match' style={{fontWeight: "700"}}>Exact match</p>
          : <>
            <p className='pm-match' style={{fontWeight: "normal"}}><b>{article.match_count}</b> matching word{article.match_count>1 ? "s" : ""}</p>
            <p className='bi-match' style={{fontWeight: "normal"}}><b>{article.bi_match_count}</b> matching bigram{article.bi_match_count>1 ? "s" : ""}</p>
            </>
          }
        </div>
        <div>
          <b>Links:</b>
          <p>pmid: <a className="link" href={`https://pubmed.ncbi.nlm.nih.gov/${article["pmid"]}`} target="_blank" rel="noreferrer">{article["pmid"]}</a></p>
          <p>pmc: {article["pmc"] ? <a className="link" href={`https://www.ncbi.nlm.nih.gov/pmc/articles/${article["pmc"]}`} target="_blank" rel="noreferrer">{article["pmc"]}</a> : "Not available"}</p>
        </div>
      </td>
      <td>
        <h2 className="pubmed-demo_journal">{article["journal"].title} : {article["journal"]["volume-issue"]}</h2>
        <h1>{article["title"]}</h1>
        <p className="search-section--output-authors">
          {article["authors"]?.slice(0, 5).map((author, index) => {
            return <Fragment key={index}>{author}{index+1!==article["authors"]?.length && ", "}</Fragment>
          })}
          {article["authors"]?.length > 5 && " (and "+(article["authors"]?.length-5)+" more named author"+(article["authors"]?.length-5>1 ? "s" : "")+")"}
        </p>
        <p className="search-section--output-abstract" dangerouslySetInnerHTML={{__html: article["abstract"]}}></p>
        <span className="search-section--show-more" onClick={(e) => {e.target.classList.toggle("show-all")}}></span>
        <p className="search-section--output-dates" style={{color: "#555", fontSize: "0.98rem", marginTop: "0.5rem"}}><b>Published: </b>{article["publish-date"]}, <b>Last Revised: </b>{article["revision-date"]}</p>
      </td>
    </tr>
  )
}

import SoftwareTemplate from "./SoftwareTemplate";
import { useEffect, useState } from "react";


export default function JanglePage() {

  return (
    <SoftwareTemplate
      name={"Jangle"}
      tag={"Jaccard n-Gram Lexical Evaluator"}
      desc={"One of our text analysis programs Jangle, the Jaccard N-Gram Lexical Evaluator, compares texts based on groups of words known as n-grams. It is capable of finding and comparing a selectable length of strings of words and calculating similarity scores based on this."}
      DemoDesc={() => <p>Discover how n-grams work with this simple demo below.</p>}
      Demo={<JangleDemo />}
      features={["Compare multiple files and calculate a score of similarity (Jaccard)"]}
    />

    //   <section id="extra-section">
    //     <h2>Extra information:</h2>
    //   </section>

  )
}


function JangleDemo() {

  const maxChars = 100;
  const [demoValue, setDemoValue] = useState("This sentence contains some n-grams.");

  const [gramLen, setGramLen] = useState(3);
  const [nGrams, setNGrams] = useState([]);

  useEffect(() => {
    let text = demoValue;
    if (text.length > maxChars) {
      text = text.substring(0, maxChars);
      setDemoValue(text);
    }

    let newGrams = new Set();
    for (let sent of text.split(/[\\.!?]/)) {
      const words = sent.split(/(?=[\W+])|(?<=[\W+])/g).filter(w => /[a-zA-Z0-9]/.test(w));
      for (let i=0; i<words.length-gramLen+1; i++) {
        newGrams.add(words.slice(i, i+Number(gramLen)).join("_"));
      }
    }
    console.log(newGrams)
    setNGrams(Array.from(newGrams));
  }, [demoValue, gramLen])


  return (
    <div className="lfmdemo" style={{flexDirection: "column"}}>
      <div className="lfmdemo_container" style={{minHeight: "6lh"}}>
        <div className="lfmdemo_topbar"></div>
        <div className="lfmdemo_textbox">
          <span className="lfmdemo_characters">{demoValue.length}/{maxChars}</span>
          <textarea className="lfmdemo_text-input" style={{color: "var(--text-400)"}}
                    value={demoValue} onChange={e => setDemoValue(e.target.value)} />
        </div>
      </div>
      <div className="lfmdemo_legend-container" style={{display: "flex", justifyContent: "space-between", overflowX: "auto"}}>
          <div>
            <h3><input type="number" value={gramLen} onChange={e => setGramLen(e.target.value)} style={{width: "3ch"}} min={1} max={7} />-grams:</h3>
            <div style={{overflowY: "auto", paddingBlockEnd: "0.5lh"}}>
              <ul>
              {nGrams?.length > 0
              ? nGrams?.sort().map((gram, index) => {
                  return <li key={index}>{gram}</li>
                })
              : <i>none found</i>
              }
              </ul>
            </div>
          </div>

      </div>
    </div>
  )
}

import './ComparisonCenter.css';
import Data from './data';

import { Fragment, useMemo, useState } from 'react';


export default function ComparisonCenter( ) {

  const files1 = [Data.files["0"]];
  const files2 = [Data.files["1"]];
  const matches = Data.matches;

  const fileId1 = "0";
  const fileId2 = "1";

  const currentMatches = matches?.[fileId1+'='+fileId2];

  function matchHovered(file, id) {
    let folder = 'sent_nums';
    let target = 2
    if (file === 2) {
      folder = 'sent_nums_reverse';
      target = 1
    }

    for (let match of Object.keys(currentMatches[folder][id])) {
      let highlightId = target + '-' + match;
      const element = document.getElementById(highlightId);
      element?.classList?.add('highlight');
    }
  }

  function matchUnhovered(file, id) {
    let folder = 'sent_nums';
    let target = 2
    if (file === 2) {
      folder = 'sent_nums_reverse';
      target = 1
    }

    for (let match of Object.keys(currentMatches[folder][id])) {
      let highlightId = target + '-' + match;
      document.getElementById(highlightId)?.classList.remove('highlight');
    }
  }

  function matchClicked(e, file, id) {
    if (e.target.tagName === 'A') return;

    if (!document.getElementById(file+'-'+id).classList.contains('focussed')) {
      for (let elem of document.querySelectorAll('.focussed')) {
        elem.classList.remove('focussed');
      }
    }

    let folder = 'sent_nums';
    let target = 2
    if (file === 2) {
      folder = 'sent_nums_reverse';
      target = 1
    }

    let focussed = focusElement(file+'-'+id);
    for (let match of Object.keys(currentMatches[folder][id])) {
      focusElement(target+'-'+match, true, !focussed);
    }
    if (focussed) {
      const match = Object.keys(currentMatches[folder][id])[0];
      document.getElementById(target+'-'+match)?.scrollIntoView({block: "nearest", inline: "nearest"});
    }
  }

  function focusElement(id, override=false, focus=false) {
    const clickedElement = document.getElementById(id);

    if (!override) { focus = clickedElement?.classList.contains('focussed') }

    if (focus) {
      clickedElement?.classList.remove('focussed');
      return false;
    }
    else {
      clickedElement?.classList.add('focussed');
      return true;
    }
  }

  const [sentFilter, setSentFilter] = useState(0);
  const onlyMatching = useMemo(() => {
    return sentFilter === 1;
  }, [sentFilter])
  const onlyExactMatching = useMemo(() => {
    return sentFilter === 2;
  }, [sentFilter])

  return (
    <>
    <div className='doccomp-demo'>

      <div className='comparison-stats'>
        <label>
          <input type="radio" name="sent-filter" checked={sentFilter === 0} onChange={() => setSentFilter(0)} />
          All sentences
        </label>
        <label>
          <input type="radio" name="sent-filter" checked={sentFilter === 1} onChange={() => setSentFilter(1)} />
          Only matches
        </label>
        <label>
          <input type="radio" name="sent-filter" checked={sentFilter === 2} onChange={() => setSentFilter(2)} />
          Only exact matches
        </label>
      </div>

      <div className='comparison-center'>
        <DocumentViewer
          id={1}
          files={files1}
          matches={currentMatches?.['sent_nums']}
          reverseMatches={currentMatches?.['sent_nums_reverse']}
          onlyMatching={onlyMatching}
          onlyExactMatching={onlyExactMatching}
          matchClicked={(e, id) => matchClicked(e, 1, id)}
          onMouseEnter={(id) => matchHovered(1, id)}
          onMouseLeave={(id) => matchUnhovered(1, id)}
        />
        <DocumentViewer
          id={2}
          files={files2}
          matches={currentMatches?.['sent_nums_reverse']}
          reverseMatches={currentMatches?.['sent_nums']}
          onlyMatching={onlyMatching}
          onlyExactMatching={onlyExactMatching}
          matchClicked={(e, id) => matchClicked(e, 2, id)}
          onMouseEnter={(id) => matchHovered(2, id)}
          onMouseLeave={(id) => matchUnhovered(2, id)}
        />
      </div>

    </div>

    </>
  )

}


function DocumentViewer( {id, files, matches, reverseMatches, onlyMatching, onlyExactMatching, matchClicked, onMouseEnter, onMouseLeave} ) {

  const matches_list = matches && Object.keys(matches);

  function buildMatchSent(sentence, match_list) {
    let out = []
    let wordCount = 0
    for (let word of sentence) {
      if (word !== '' && /^[A-Za-z0-9]/.test(word)) {
        out.push(<span className={'match-type-'+match_list[wordCount]}>{word}</span>)
        wordCount += 1;
      }
      else out.push(<>{word}</>)
    }
    return out;
  }

  return (
    // <div className='document-viewer'>

      <div id={'textbox-'+id} className={'document-viewer-textbox' + (onlyMatching ? ' matched-only' : '')}>
        {files?.[0]?.sentences?.map((sentence, num) => {
          const addBreak = files?.[0]?.breaks?.includes(num)
          sentence = sentence.split(/([ .,/#!?'"$%^&*;:{}=_`~()])/);

          if (matches_list?.includes((num).toString())) {
            const this_matches_list = Object.keys(matches[num]);
            const this_matches_len = this_matches_list.length

            let matchesExactly = false;
            if (onlyExactMatching) {
              matchesExactly = this_matches_list.map(m => (matches[num][m].every(v => v === 2) && reverseMatches[m][num].every(v => v === 2))).includes(true)
            }

            if (!onlyExactMatching || matchesExactly) {
              return (
                <Fragment key={num}>
                  {addBreak && !onlyMatching && <br className='paragraph-break'/>}
                  <span
                    id={id+'-'+(num)}
                    className='sentence match'
                    onClick={(e) => matchClicked(e, num)}
                    onMouseEnter={() => onMouseEnter(num)}
                    onMouseLeave={() => onMouseLeave(num)}
                  >
                    {buildMatchSent(sentence, matches[num][this_matches_list[0]]).map((sec, index) => {
                      return <Fragment key={index}>{sec}</Fragment>;
                    })}
                    &nbsp;
                    <div className='info'>
                      Sentence {num+1} -  {this_matches_len} match{this_matches_len !== 1 && 'es'}:
                      {this_matches_list.map((match_num, index) => {return <Fragment key={index}><br/> - sentence {Number(match_num)+1} {matches[num][match_num].every(v => v === 2) && '(exact match)'}</Fragment>})}
                    </div>
                  </span>
                </Fragment>
              )
            }
          }

          return (
            onlyMatching || onlyExactMatching
              ? <Fragment key={num}></Fragment>
              : <Fragment key={num}>{addBreak && <br className='paragraph-break'/>}<span className='sentence' key={num} id={id+'-'+(num)}>{sentence} </span></Fragment>
          )
        })}
      </div>
    // </div>
  )
}

import { Link } from "react-router-dom";
import "./SoftwareTemplate.css";

import { useState, useEffect } from "react";


export default function SoftwareTemplate({ name, desc, tag, DifferentButton, Details, DemoDesc, Demo, features }) {

  document.title = name+" | Vocalyse Software";

  const softwareTitle = name;
  const [titleText, setTitleText] = useState("");
  const [hiddenText, setHiddenText] = useState(softwareTitle);
  useEffect(() => {
    async function typeTitle() {
      const titleLen = softwareTitle.length;
      for (let i=0; i<titleLen; i++) {
        let delay = 90 - Math.random() * 50;
        await new Promise(r => setTimeout(r, delay));

        setTitleText(softwareTitle.slice(0, i+1));
        setHiddenText(softwareTitle.slice(i+1, titleLen));
      }
      setTitleText(softwareTitle);
      setHiddenText("");
    }
    typeTitle();
  }, [softwareTitle]);


  return (
    <main className="page-grid software-page">
      <section className="software_heading">
        <div className="software_heading-container">
          <h1 className="software_title">
            {titleText}
            {hiddenText.length > 0 &&
              <span className="homepage_header-hidden">
                {hiddenText}<span className="homepage_header-cursor">|</span>
              </span>
            }
          </h1>
          <hr />
          <p className="software_tag">{tag}</p>

          <div className="software_heading-buttons">
            {DifferentButton
            ? DifferentButton
            : <button onClick={() => document.getElementById("demo").scrollIntoView({behavior: "smooth"})} className="primary-button small-button">Try demo</button>}
            <Link to="/contact" className="secondary-button small-button">Enquire</Link>
          </div>
        </div>
      </section>

      {Details &&
        <section className="software_details">
          <Details />
        </section>
      }

      <section className="software_demo" id="demo">
        <div className="software_demo-container">
          <div>
            <h2>Demo:</h2>
            {DemoDesc && <DemoDesc />}
          </div>

          <div className="software_demo-box">
            {Demo && Demo}
          </div>
        </div>
      </section>

      <section className="software_features" id="details">
        <div className="software_features-container">
          <h2>Full features</h2>
          <ul>
            {features?.map((feature, index) => {
              return (<li key={index}>{feature}</li>)
            })}
          </ul>          
        </div>

      </section>

    </main>
  )
}
